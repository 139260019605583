/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { ApiRequest, WHATA_ENDPOINTS } from "@plinknz/tah-website-elements";
import * as ROUTES from "../../../../config/router";
import { Field } from "../../../../components/form/field";
import { EMAIL_REGEX } from "../../../../config/regex";
import { UserContext } from "../../../../service/reducers/user";
import { whataRequest } from "../../../../service/member/whata";
import { parseError } from "../../../../utility/parse-error-html";

interface FormData {
    email: string;
}
interface SetupLoginProps {
    whata?: ApiRequest;
}

export const SetupLogin = ({ whata = whataRequest }: SetupLoginProps) => {
    const {
        state: { resetSent, error },
        dispatch,
    } = React.useContext(UserContext);
    const { register, handleSubmit, errors, trigger, getValues } = useForm<
        FormData
    >();

    const [noEmailError, setNoEmailError] = useState(false);
    const [noRegisterError, setNoRegisterError] = useState(false);

    function reset() {
        dispatch({ type: "reset" });
    }

    function resetError() {
        dispatch({ type: "error", error: "" });
    }

    useEffect(() => {
        if (error) {
            setNoEmailError(!!error.match("Could not find any data"));
            setNoRegisterError(!!error.match("Member's must be accepted"));
        } else {
            setNoEmailError(false);
            setNoRegisterError(false);
        }
        return resetError;
    }, [error]);

    async function handleFormSubmit(formData: FormData) {
        dispatch({ type: "loading", isLoading: true });
        dispatch({ type: "error", error: null });

        try {
            const { data, status } = await whata.post(
                WHATA_ENDPOINTS.password(),
                {
                    email: formData.email,
                    tenantId: "NRAIT",
                    redirect: `${window.location.protocol}//${
                        window.location.host
                    }${ROUTES.membersLoginNewPassword()}`,
                }
            );

            const errorThreshold = 399;

            if (status > errorThreshold) {
                throw data;
            }

            dispatch({ type: "set_reset_sent", resetSent: true });
        } catch (submitError: unknown) {
            let errorMessage: string | null =
                "There was an error when trying to set your password. Please try again";

            if (typeof submitError === "string") {
                errorMessage = parseError(submitError, errorMessage);
            } else if ("message" in (submitError as any)) {
                errorMessage = (submitError as any).message;
            }

            dispatch({
                type: "error",
                error: errorMessage,
            });
        } finally {
            dispatch({ type: "loading", isLoading: false });
        }
    }

    const isValid = () => !errors.email && getValues().email;

    return (
        <form
            className="form"
            onSubmit={handleSubmit(handleFormSubmit)}
            data-testid="reset-password">
            {error &&
                ((noEmailError && (
                    <div className="form-hint">
                        We do not have your email on record, please{" "}
                        <a
                            href="mailto:info@whakarewa.nz?subject=Inquiries from Whakarewa website"
                            target="_blank"
                            rel="noreferrer">
                            click here
                        </a>{" "}
                        to contact us so we can update.
                    </div>
                )) ||
                (noRegisterError && (
                    <div
                        data-testid="email-form-error"
                        className="form-hint">
                        Your application is still being processed, once this
                        is completed you will be able to setup your login.
                    </div>
                )))
            }
            {resetSent && (
                <div data-testid="reset-message" className="form-alert">
                    We have sent you an email which you can use to set up your
                    password.
                </div>
            )}
            <Field
                label="Email Address"
                name="email"
                error={{
                    hasError: !!errors.email,
                    message: "Please enter a valid email address",
                }}
                ref={register({ required: true, pattern: EMAIL_REGEX })}
                placeholder="Email address"
                onChange={async (e) => trigger(e.currentTarget.name as "email")}
                type="text"
                data-testid="email"
                required
            />
            <div className="form-navigation">
                <Link
                    className="button secondary"
                    onClick={reset}
                    to={ROUTES.membersLogin()}>
                    Back
                </Link>
                <button
                    className="button tertiary next"
                    type="submit"
                    data-testid="button"
                    onClick={reset}
                    disabled={!isValid()}>
                    Next
                </button>
            </div>
        </form>
    );
};
