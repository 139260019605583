import * as React from "react";
import { Col, Row } from "react-bootstrap";
import {
  WHAKAREWA_FACEBOOK,
  WHAKAREWA_VIMEO,
  PLINK_LINK,
} from "../config/external";

export const Footer = () => {
  interface AddressProps {
    title?: string;
    subtitle?: string;
    phoneNumber?: string;
    address?: string[];
    email: string;
    map?: string;
  }
  const Address = ({ phoneNumber, address, email, map }: AddressProps) => (
    <>
      <Row className="footer-row">
        <Col className="col-2 footer-phone"/>
        <Col className="col-10 footer-text">
          <a data-testid="phone" href={`tel:${phoneNumber}`}>{phoneNumber}</a>
        </Col>
      </Row>
      <Row className="footer-row">
        <Col className="col-2 footer-email"/>
        <Col className="col-10 footer-text">
          <a
            href={`mailto:${email}?subject=Whakarewa Website Enquiry`}
            target="_blank"
            data-testid="email"
            rel="noreferrer">
            {email}
          </a>
        </Col>
      </Row>
      <Row className="footer-row">
        <Col className="col-2 footer-gmap"></Col>
        <Col className="col-10 footer-text">
          <a href={map} target="_blank" rel="noreferrer">
            {address.map((line, idx) => (<div key={`address-line-${idx}`} data-testid={`address-line-${idx}`}>{line}</div>))}
          </a>
        </Col>
      </Row>
    </>
  );
  const socialLinks = () => (
    <div>
      <div>
        <a href={WHAKAREWA_FACEBOOK} target="_blank" rel="noreferrer">
          <i className="fab fa-facebook-square footer-facebook footer-icon-inflate" />
        </a>
        <a
          href={WHAKAREWA_FACEBOOK}
          className="footer-links"
          target="_blank"
          data-testid="social-facebook"
          rel="noreferrer"
        >
          Facebook
        </a>
      </div>
      <div>
        <a href={WHAKAREWA_VIMEO} target="_blank" rel="noreferrer">
          <i className="fab fa-brands-square fa-vimeo-v footer-instagram footer-icon-inflate" />
        </a>
        <a
          href={WHAKAREWA_VIMEO}
          className="footer-links"
          target="_blank"
          data-testid="social-vimeo"
          rel="noreferrer"
        >
          Vimeo
        </a>
      </div>
    </div>
  );

  return (
    <div className="footer">
      <div className="footer-layer secondary">
        <div className="constrain-width">
          <Row>
            <Col className="col-12">
              <div className="footer-logo" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={7} className="footer-divider-right">
              <Address
                phoneNumber="03 548 0770"
                email="info@whakarewa.nz"
                map="https://www.google.com/maps/place/Wakatu+Incorporation/@-41.2731968,173.2795343,17z/data=!3m1!4b1!4m5!3m4!1s0x6d3bed102aed8883:0xc4f6509fa9678148!8m2!3d-41.2732009!4d173.281723"
                address={[
                  "Level 1, Wakatū House",
                  "Montgomery Square",
                  "PO Box 13",
                  "Nelson 7040"
                ]}
              />
            </Col>
            <Col xs={12} md={3} md-offset={2}>
                <div className="footer-social">
                  {socialLinks()}
                </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={7}>
              <p className="copyright-text" data-testid="copyright">
                Copyright © All Rights Reserved
              </p>
            </Col>
            <Col xs={12} md={3} md-offset={2}>
              <p className="copyright-plink">
                <a
                  className="copyright-link"
                  href={PLINK_LINK}
                  data-testid="creator"
                  target="_blank"
                  rel="noreferrer"
                >
                  Nā Plink Software tēnei
                </a>
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
