import * as React from "react";
import { Link } from "react-router-dom";
import { Hero } from "../components/hero";

export const NotFound = () => {
    return (
        <>
            <Hero
                title="Auē, kua ngaro tēnei whārangi"
                subtitle="Sorry. Page not found."
            />
            <div className="constrain-width" data-testid="not-found">
                <p>
                    You may have mistyped the address or the page may have
                    moved. Please click <Link to="/">here</Link> to head to our
                    home page.
                </p>
            </div>
        </>
    );
};
