import { classNames } from "@plinknz/tah-website-elements";
import React, { useEffect, useState } from "react";
import Headroom from "react-headroom";
import { Link } from "react-router-dom";
import { Navigation } from "./navigation";
import { MemberNavigation } from "./member-navigation";

export interface HeaderProps {
  enableNavigation?: boolean;
}

export const Header = ({ enableNavigation = true }: HeaderProps) => {
  const [colorChange, setColorChange] = useState(false);
  const changeNavbarColor = () => setColorChange(window?.scrollY >= 80);
  const headerClass = classNames("headroom", {
    colorChange,
  });

  useEffect(() => {
    window?.addEventListener("scroll", changeNavbarColor);

    return () => window?.removeEventListener("scroll", changeNavbarColor);
  }, []);

  return (
    <Headroom>
      <header className={headerClass} role="banner">
        <div className="header-secondary">
          <div className="header-secondary-inner || constrain-width large">
            <MemberNavigation />
          </div>
        </div>
        <div className="header-inner || constrain-width large">
          <Link to="/" className="header-logo">
            Whakarewa
          </Link>
          <h1 className="header-title">
            <Link
              className="header-title-link"
              to="/"
              data-testid="header-title"
            >
              Whakarewa
            </Link>
          </h1>
          {enableNavigation && <Navigation />}
        </div>
      </header>
    </Headroom>
  );
};
