/* eslint-disable react/jsx-max-depth */
import { parse, format } from "date-fns";
import * as React from "react";
import { generate } from "shortid";
import { PageTitle } from "@plinknz/tah-website-elements";
import { Link, Route, Switch, useHistory, useLocation } from "react-router-dom";
import * as ROUTES from "../../../../config/router";
import { Loader } from "../../../../components/loader";
import { Hero } from "../../../../components/hero";
import { PAGE_SUFFIX } from "../../../../views/page";
import { UserContext } from "../../../../service/reducers/user";
import { fetchUser } from "../../../../utility/load-member";
import { getPhone, PhoneType } from "../../../../utility/phone";
import { UpdateDetails } from "./update-details.component";

export const DetailsComponent = () => {
    const {
        state: { user, member },
        dispatch,
    } = React.useContext(UserContext);
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const history = useHistory();
    const location = useLocation();
    
    async function populateUser() {
        setLoading(true);
        await fetchUser({
            dispatch,
            history,
            location,
            id: user.id,
        });
    }

    React.useEffect(() => {
        if (user?.id && !member) {
            populateUser();
        }
    }, [user?.id]);

    React.useEffect(() => {
        if (member) {
            setLoading(false);
        }
    }, [member]);

    const renderLine = (v: string | string[]) => {
        if (!v) {
            return null;
        }

        if (Array.isArray(v)) {
            return (
                <React.Fragment key={generate()}>
                    {v.map(renderLine)}
                </React.Fragment>
            );
        }

        return <p key={generate()}>{v}</p>;
    };
    type ColumnValue =
        | (string | null)
        | (string | string[] | undefined)
        | (string | string[][] | undefined);

    const renderRow = (columns: ColumnValue[]) => (
        <tr key={generate()} className="table-row">
            {columns.map((value: ColumnValue) => (
                <td className="table-cell" key={generate()}>
                    {(Array.isArray(value) &&
                        ((value as unknown) as string[]).map(renderLine)) ||
                        value ||
                        "-"}
                </td>
            ))}
        </tr>
    );
    const renderTable = (
        rows: (
            | (string | null)[]
            | (string | string[] | undefined)[]
            | (string | string[][] | undefined)[]
        )[]
    ) => (
        <table className="table">
            <tbody>{rows.map(renderRow)}</tbody>
        </table>
    );

    const rows = [
        ["Title", member?.title],
        ["First Name", member?.firstName],
        ["Middle names", member?.middleNames],
        ["Surname", member?.surname],
        ["Aliases", member?.aliases],
        ["Email", member?.email?.map(({ value }) => value)],
        [
            "Address",
            member?.address?.map((address) => [
                address.line1,
                address.line2,
                address.suburb,
                `${address.city} ${address.postcode}`,
                address.state,
                address.country,
            ]),
        ],
        ["Phone", getPhone({ phone: member?.phone, type: PhoneType.Home })],
        ["Cell", getPhone({ phone: member?.phone, type: PhoneType.Cell })],
        ["Gender", member?.gender],
        [
            "Date of Birth",
            member?.dob
                ? format(
                      parse(member?.dob, "yyyymmdd", new Date()),
                      "dd/mm/yyyy"
                  )
                : null,
        ],
        ["Occupation", member?.occupation],
    ];

    return (
        <div data-testid="moku-details">
            <PageTitle title="Mōku" suffix={PAGE_SUFFIX}/>
            <Hero title="My Details" subtitle="Mōku" />
            <div className="constrain-width">
                <Switch>
                    <Route path={ROUTES.membersDetailsEdit()}>
                        <UpdateDetails />
                    </Route>
                    <Route path={ROUTES.membersDetails()}>
                        {isLoading && (
                            <div data-testsid="loader" className="splash">
                                <Loader size="large" />
                            </div>
                        )}
                        {member && (
                            <div data-testid="member-details">
                                {renderTable(rows)}
                                <Link
                                    to={ROUTES.membersDetailsEdit()}
                                    className="button secondary"
                                    type="button">
                                    Edit my information{" "}
                                    <i className="button-icon || fa fa-pencil-alt" />
                                </Link>
                            </div>
                        )}
                    </Route>
                </Switch>
            </div>
        </div>
    );
};
