import * as React from "react";
import {
    getMemaSlug,
} from "@plinknz/tah-website-elements";
import { Page } from "../../../views/page";

export const MemberPage = () => {
    const slug = getMemaSlug(location);
    return (
        <Page
            suffix="Members"
            slugOverride={ slug }
            memberPages={true}
        />
    );
};
