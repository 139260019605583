import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { WHATA_ENDPOINTS } from "@plinknz/tah-website-elements";
import * as ROUTES from "../../../config/router";
import { UserContext } from "../../../service/reducers/user";

export const LoginOptions = () => {
    const {
        state: { error },
        dispatch,
    } = React.useContext(UserContext);

    const [noFbookError, setNoFbookError] = useState(false);
    const [noUserError, setNoUserError] = useState(false);

    function handleFacebookClick() {
        dispatch({ type: "loading", isLoading: true });
        const redirect = `${window.location.protocol}//${
            window.location.host
        }${ROUTES.membersLogin()}`;

        const failureRedirect = `${redirect}%3FFBError=true`;

        window.location.href = `${
            process.env.WHATA_URL
        }${WHATA_ENDPOINTS.pukamata()}?successRedirect=${redirect}&tenantId=NRAIT&failureRedirect=${failureRedirect}`;
    }

    function resetError() {
        dispatch({ type: "error", error: "" });
    }

    useEffect(() => {
        if (window.location.href.indexOf("%3FFBError=true") == -1) {
            if (window.location.href.indexOf("&notAccepted=true") == -1) {
                setNoFbookError(true);
                setNoUserError(false);
            } else {
                setNoFbookError(false);
                setNoUserError(true);
            }
        } else {
            setNoFbookError(false);
            setNoUserError(false);
        }
        return resetError;
    }, []);

    function renderOptions() {
        return (
            <div className="button-list">
                {error &&
                    ((noFbookError && (
                        <div className="form-hint">
                            We do not have your email from Facebook in our database.
                            Please{" "}
                            <a
                                href="mailto:info@whakarewa.nz.nz?subject=Inquiries from Whakarewa website"
                                target="_blank"
                                rel="noreferrer">
                                click here
                            </a>{" "}
                            to send us a message.
                        </div>
                    )) ||
                    (noUserError && (
                            <div
                                data-testid="email-form-error"
                                className="form-hint">
                                Your application is still being processed, once
                                this is completed you will be able to setup your
                                login.
                            </div>
                    )))
                }
                <button
                    data-testid="login-facebook"
                    type="button"
                    className="button large facebook"
                    onClick={handleFacebookClick}>
                    <i className="button-fbook || fab fa-facebook-f" /> Login
                    with Facebook
                </button>
            </div>
        );
    }

    return (
        <div className="main" data-testid="login">
            <Route path="*">{renderOptions}</Route>
        </div>
    );
};